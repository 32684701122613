import React, {Component} from 'react'
import { Link } from "react-router-dom"
import classes from "./Header.module.scss"
import NavItems from "./NavItems"
import Drawer from "./Drawer"
import { BookNow } from '../Book/Book'
import Email from "../Shared/Email"
import { Wave2 } from "../../components/Shapes/Shapes"
import ventW from "../../assets/images/ventW.png"
import ventP from "../../assets/images/vent.png"

class Header extends Component {
  render() {
    return (
      <div className={[classes.fullSec, classes.headerSec, classes[this.props.version]].join(' ')}>
        <div className={[classes.sectionWrap, classes.insidePw].join(' ')}>
          <header className={classes.header}>
            <div className={[classes.fullSec, classes.navRow].join(' ')}>
              <div className={classes.mobNav}><Drawer /></div>
              <nav className={[classes.nrItem, classes.navWrap].join(' ')}>
                <NavItems where="header" navClass="mainNav" hideBook={true} />
              </nav>
              <div className={[classes.nrItem, classes.logoItem].join(' ')}>
                <Link to="/" className={[classes.logo, classes.logoW].join(' ')}><img src={ventW} alt="Vent logo - professional listening for people in need" /></Link>
                <Link to="/" className={[classes.logo, classes.logoP].join(' ')}><img src={ventP} alt="Vent logo - listening and emotional support" /></Link>
              </div>
              <div className={[classes.nrItem, classes.auxWrap].join(' ')}>
                <ul>
                  <li className={[classes.navItem, classes.iemail].join(' ')}><Email where="nav" /></li>
                  <li className={[classes.navItem, classes.ibk].join(' ')}><BookNow addClass="kpLinkSm" /></li>
                </ul>
              </div>
            </div>
          </header>
        </div>
        <div className={[classes.fullSec, classes.shapeWrap, classes.postShape].join(" ")}>
          <Wave2 color1="color1" color2="color1" addClass="rev" hide2={true} hide3={true} />
        </div>
      </div>
    );
  }
}
export default Header;