import React, { useState, useEffect } from 'react'
import scrollSmoothTo from './Smooth'

export default function BackToTop() {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      if (scrollY > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div classes="toTopWrap">
      {isVisible && (
      <button 
        onClick={() => scrollSmoothTo('ventTop')}
        title="Scroll to top"
        className="upBtn"
      >
        <span className="uk-hidden-visually">Scroll to top</span>
        <span data-uk-icon="icon: triangle-up"></span>
      </button>
      )}
    </div>
  )
}