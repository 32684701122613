import React, {Component} from 'react'
import classes from "./Footer.module.scss"
import { Wave2 } from "../../components/Shapes/Shapes"
import Disclaimer from "../Shared/Disclaimer"
import NavItems from '../Header/NavItems'
import { BookNow } from '../Book/Book'
import Email from '../Shared/Email'

class Footer extends Component {
  render() {
    return (
      <div className={[classes.fullSec, classes.footerSec].join(' ')}>
        <div className={[classes.fullSec, classes.shapeWrap].join(" ")}>
          <Wave2 color1="color1" color2="color1"  hide2={true} hide3={true} />
        </div>
        <footer className={classes.footer}>  
          <div className={[classes.menuWrap, classes.medDef].join(' ')}>
            <NavItems />
            <ul className={classes.aux}>
              <li><BookNow addClass="rev" /></li>
              <li><Email /></li>
            </ul>
          </div>
          <div className={[classes.discWrap, classes.medDef].join(' ')}>
            <p className={classes.disclaimer}><Disclaimer /></p>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;