import classes from "./Shapes.module.scss"

export function Wave1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2100 375"
      preserveAspectRatio="none"
      className={[
        classes.shapeGroup,
        classes.wave1,
        classes[props.addClass ?? "waveDef"]
      ].join(" ")}
    >
      {/* OG removed: 
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="2100" height="400" preserveAspectRatio="none" viewBox="0 0 2100 400">
        (around paths) <g mask="url(&quot;#SvgjsMask1179&quot;)" fill="none">  
        (after paths) <defs><mask id="SvgjsMask1179"><rect width="2100" height="400" fill="#ffffff"></rect></mask></defs> 
      */}
      {props.hide1 === true ? null : (
        <path
          d="M 0,95 C 84,87.2 252,59.4 420,56 C 588,52.6 672,80.8 840,78 C 1008,75.2 1092,35 1260,42 C 1428,49 1512,112.2 1680,113 C 1848,113.8 2016,59.4 2100,46L2100 400L0 400z"
          className={[classes.path, classes.p1, classes[props.color1 ?? 'inh' ]].join(' ')}
        ></path>
      )}
      {props.hide2 === true ? null : (
        <path
          d="M 0,258 C 140,240.8 420,172.8 700,172 C 980,171.2 1120,255.4 1400,254 C 1680,252.6 1960,182.8 2100,165L2100 400L0 400z"
          className={[classes.path, classes.p2, classes[props.color2 ?? 'inh' ]].join(' ')}
        ></path>
      )}
      {props.hide3 === true ? null : (
        <path
          d="M 0,345 C 105,329.4 315,267.2 525,267 C 735,266.8 840,332.6 1050,344 C 1260,355.4 1365,325 1575,324 C 1785,323 1995,336 2100,339L2100 400L0 400z"
          className={[classes.path, classes.p3, classes[props.color3 ?? 'inh' ]].join(' ')}
        ></path>
      )}
    </svg>
  )
}

export function Wave2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2100 375"
      preserveAspectRatio="none"
      className={[
        classes.shapeGroup,
        classes.wave2,
        classes[props.addClass ?? "waveDef"]
      ].join(" ")}
    >
      {/* OG removed (same as above except): 
        (after paths)<defs><mask id="SvgjsMask1208"><rect width="2100" height="400" fill="#ffffff"></rect></mask></defs>
      */}
      {props.hide1 === true ? null : (
        <path d="M 0,68 C 84,58.8 252,21.6 420,22 C 588,22.4 672,70.6 840,70 C 1008,69.4 1092,7.6 1260,19 C 1428,30.4 1512,117.8 1680,127 C 1848,136.2 2016,77.4 2100,65L2100 400L0 400z" 
          className={[classes.path, classes.p1, classes[props.color1 ?? 'inh' ]].join(' ')}
        ></path>
      )}
      {props.hide2 === true ? null : (
        <path d="M 0,249 C 105,227.2 315,145.2 525,140 C 735,134.8 840,213.8 1050,223 C 1260,232.2 1365,186 1575,186 C 1785,186 1995,215.6 2100,223L2100 400L0 400z" 
          className={[classes.path, classes.p2, classes[props.color2 ?? 'inh' ]].join(' ')}
        ></path>
      )}
      {props.hide3 === true ? null : (
        <path d="M 0,272 C 210,296.4 630,386.8 1050,394 C 1470,401.2 1890,325.2 2100,308L2100 400L0 400z" 
          className={[classes.path, classes.p3, classes[props.color3 ?? 'inh' ]].join(' ')}
        ></path>
      )}
    </svg>
  )
}