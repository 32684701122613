import * as React from 'react'
import classes from './Header.module.scss'
import NavItems from './NavItems'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
// import Social from '../Social/Social'
import { BookNow } from '../Book/Book'
import Email from '../Shared/Email'
// import Phone from '../Shared/Phone'

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  }

  const navList = side => (
    <div
      className={[classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><CloseIcon /></div>
      <NavItems navClass="SideNav" />
      {/* <button onClick={handleClick}>Book Me</button> */}
      <div className={classes.drEmail}><Email where="drawer" /></div>
      <BookNow addClass="rev" />
    </div>
  )

  return (
    <div className={[classes.NavDrawer].join(' ')}>
      <Button onClick={toggleDrawer('left', true)} className={classes.menuBtn}>
        <MenuIcon />
      </Button>

      <SwipeableDrawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
        className={classes.cNavDrawer}
      >
        {navList('left')}
      </SwipeableDrawer>
    </div>
  );
}