import React from 'react'
import { Helmet } from 'react-helmet'
import classes from './Contact.module.scss'
import Email from '../../components/Shared/Email'
import { BookNow } from '../../components/Book/Book'

export default function Contact() {
  const session = <span className={classes.em}>Venting Session</span>
 
  return (
    <div className={[classes.fullSec, classes.Contact].join(" ")}>
      <Helmet>
        <title>Book a Venting Session With Your Personal Confidant</title>
        <meta
          name="description"
          content="Contact Vent to book a session today. Let us help clear your mind, and ease the stress and pressures life brings."
        />
      </Helmet>

      <div className={[classes.fullSec, classes.intro].join(" ")}>
        <div className={[classes.med2Def, classes.iwrap].join(" ")}>
          <h1>Reach out to <span className={classes.color1}>Vent</span> today,<br/> <b>and discover the <span className={classes.color2}>power of being heard.</span></b></h1>
          <p>Feel free to contact us if you have questions about the services we provide, or if you are interested in booking a {session} outside of our normal hours of operation.</p>
        </div>
      </div>
      <div className={[classes.fullSec, classes.contactInfo].join(" ")}>
        <div className={[classes.med3Def, classes.ciWrap, classes.flexRow, classes.jbw, classes.ac].join(" ")}>
          <p className={classes.email}><span className={classes.cat}>Email:</span><br/><Email where="contact" /></p>
          <p><span className={classes.cat}>Hours:</span><br/>Monday-Friday: 8:30am-5:30pm</p>
          <div className={classes.ibk}><BookNow btnText="Book a Session" /></div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.formBlock].join(" ")}>
        <div className={[classes.formWrap, classes.med3Def].join(" ")}>
          <form id="contactForm" action="https://formspree.io/f/xdknzldn" method="POST" className={[classes.fullSec, classes.cform].join(' ')}>
            <input type="hidden" name="_subject" id="email-subject" value="Vent Contact Form Submission" />
            <div className={[classes.halfSec, classes.formGroup].join(' ')}>
              <label>Name</label>
              <input type="text" name="name" />
            </div>

            <div className={[classes.halfSec, classes.formGroup].join(' ')}>
              <label>Email *</label>
              <input type="email" name="email" required={true} />
            </div>

            <div className={[classes.fullSec, classes.formGroup].join(' ')}>
              <label>How can we help?</label>
              <textarea type="textarea" name="message" />
            </div>

            <div className="clearfix"></div>
            <button id="contactForm-button" className={[classes.kpLink].join(' ')}>Submit</button>
            <p id="contactForm-status"></p>
          </form>
        </div>
      </div>
    </div>
  )
}
