import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./About.module.scss"
import scrollSmoothTo from "../../components/Smooth/Smooth"
import { Wave1 } from "../../components/Shapes/Shapes"
import { BookNow } from "../../components/Book/Book"
import swoopFlip from "../../assets/images/swoopFlipFlat.png"
import swoopFlat from "../../assets/images/swoopFlat.png"
import Disclaimer from "../../components/Shared/Disclaimer"

export const Uccordion = ({ title, children, open, addClass }) => {
  let isOpen = "no";
  if (open && open === "true") {
    isOpen = "uk-open";
  }
  return (
    <div
      className={[classes.accGroup, isOpen, classes[addClass ?? "reg"]].join(" ")}>
      <a className={[classes.accTitle, "uk-accordion-title"].join(" ")} href>
        {title}
      </a>
      <div className={[classes.accContent, "uk-accordion-content"].join(" ")}>
        {children}
      </div>
    </div>
  )
}

export default function About() {
  const vent = <span className={classes.em}>Vent</span>
  const venting = <span className={classes.em}>Venting</span>
  const session = <span className={classes.em}>Venting Session</span>
  const sessions = <span className={classes.em}>Venting Sessions</span>
  const fullBook = <div className={classes.fwBtn}><BookNow btnText="Book a Session" /></div>
  const mobBook = <div className={classes.mobBtn}><BookNow btnText="Book a Session" /></div>

  const how = (
    <div className={[classes.faqWrap, classes.insideTw].join(" ")}>
      <div className={[classes.flexRow, classes.body].join(" ")}>
        <div className={[classes.ilItem, classes.summ, classes.sticky].join(" ")}>
          <h3>How Does <br/>{venting} Work?</h3>
          <p className={classes.cat}>Essentials</p>
          <ul>
            <li>1 Hour Session - $45</li>
            <li>Reserve a date &amp; time</li>
            {/* <li>Choose between video or audio only</li>
            <li>Interactive discussion, or purely a decompression session</li> */}
            <li>Customize session options - video or audio only, interactive discussion or minimal input, meeting platform.</li>
            <li>Receive booking confirmation with link</li>
            <li><Link to="/contact" className="textLink">Contact us</Link> to request an after hours session.</li>
          </ul>
          {fullBook}
        </div>

        <div className={[classes.ilItem, classes.info].join(" ")}>
          <p>
            Venting Sessions are meant to empower you, so it's important that we provide options that make you feel comfortable enough 
            to get the most out of each encounter. Some people find it easier to speak freely when they have anonymity. Some 
            people prefer to see the person they are confiding in, to feel more of a connection. Whatever is good for you is good 
            for us. <span className={classes.bold}>Choose between a video or audio only session</span> when you book. 
          </p>
            
          <p>
            Our priority is being there for you, and making sure you get what you need from our interactions 
            — whether it's just someone to listen, or discussion and input. Interactive Venting Sessions tend to lead 
            to helpful and productive conversations that can spark a new perspective, or give you a chance to 
            bounce thoughts and ideas off of an impartial audience. That back-and-forth can often make the answers 
            you've searched for seem so clear. However, we also know that sometimes you just need to get it out, and 
            feel the peace that comes after saying the words outloud (or yelling them, ranting them, crying them...whatever 
            you need). <span className={classes.bold}>You set the level of interaction for each session.</span>
          </p>

          <p>
            We give you 3 options for meeting platforms - Zoom, Google Meet, and the option to request an alternative platform. After 
            you book, you will receive a confirmation email with the details for your session. <span className={classes.bold}>If you 
            chose Zoom or Google Meet, that email will include the meeting link for your session. If you requested a different platform, we 
            will look at the information you provided, and email you to make final arrangements.</span>
          </p>

          <p className={classes.bil}>
            {sessions} are $45, and last for 1 hour. You can book one, and move on, or schedule them on a regular basis. No long-term 
            commitment required. We will be here when you need us. We respect you and your privacy, and will never judge 
            you for speaking your mind. We will never try to influence your decisions, or make you feel guilty for the things you feel.
            Prioritize yourself and your mental well-being, and book your session today. <span className={classes.em}>*Sessions canceled within 2 hours of starting will not be refunded.</span>
          </p>

          {mobBook}
        </div>
      </div>
    </div>
  )

  const method = (
    <div className={[classes.faqWrap, classes.insideTw].join(" ")}>
      <div className={[classes.flexRow, classes.body].join(" ")}>
        <div className={[classes.ilItem, classes.summ, classes.sticky].join(" ")}>
          <h4>The Vent Method</h4>
          <h3>Why Should<br/> You Vent?</h3>
          <p className={classes.cat}>Essentials</p>
          <ul>
            <li>Decompress and release pent-up emotions</li>
            <li>Reduce feelings of isolation</li>
            <li>Gain emotional support</li>
            <li>Organize and process your thoughts</li>
            <li>Acknowledge and validate your feelings</li>
            <li>Work through problems effectively</li>
            <li>Find a new perspective</li>
          </ul>
          {fullBook}
        </div>

        <div className={[classes.ilItem, classes.info].join(" ")}>
          <p>
            <span className={classes.tag}>
              If you're holding it in, it's holding you down.
            </span>
            <br />
            We know that "talking it out" isn't exactly a new concept, but it is
            one that should never be overlooked for its simplicity. Voicing your
            feelings and emotions has such a powerful effect on your mood and
            mental wellness. However, not everyone has a support system, someone
            they can open up to, or access to therapy. We want to bridge that gap,
            and provide people with a comfortable and convenient option for the
            emotional outlet they need.
          </p>

          <p>
            Our {sessions} give you the opportunity to unload whatever is
            occupying your thoughts or weighing you down. Talking about a problem
            or situation you're experiencing is an extremely effective way to sort
            through your thoughts, reduce stress, and feel more in control. Even
            if you opt for a session with no input or discussion, simply talking
            about what's on your mind can bring a wave of relief that immediately
            eases tension, and makes you feel lighter.
          </p>

          <p>
            Our experienced professional listeners are supportive, sincere, and
            devoted to being there for people when they need it. Their genuine
            desire to hear and help makes you feel comfortable confiding in them,
            and open to their input when you want a new perspective.
          </p>

          <p>
            Maybe you're going through something that's wearing you down. Maybe
            you're feeling angry, overwhelmed, or alone. Maybe you're just having
            a bad day, and there's nothing more to it than that.{" "}
            <b className={classes.accent}>
              You don't always need to have a reason for what you're feeling,
            </b>{" "}
            and even more than that, there won't always{" "}
            <span className={classes.em}>be</span> a reason. No matter what you
            hope to gain when you {vent}, we want to be there for you.
          </p>
          {mobBook}
        </div>
      </div>
    </div>
  )

  const objBen = (
    <div className={[classes.faqWrap, classes.insideTw].join(" ")}>
      <div className={[classes.flexRow, classes.body].join(" ")}>
        <div className={[classes.ilItem, classes.summ, classes.sticky].join(" ")}>
          <h3>Why Should <br/>You Trust Us?</h3>

          <p className={classes.cat}>Essentials</p>
          <ul>
            <li>Objective listeners have benefits</li>
            <li>No judgement, bias, or influence</li>
            <li>Freedom to speak without holding back</li>
            <li>Complete privacy</li>
            <li>Process things without pressure</li>
          </ul>
          {fullBook}
        </div>

        <div className={[classes.ilItem, classes.info].join(" ")}>
          <p>
            <span className={classes.tag}>
              Objective listening is not impersonal.
            </span>
            <br />
            You might be surprised by how cathartic it can be to open up to
            someone who isn't directly involved in your daily life. Confiding in
            an objective listener gives you the freedom to speak more candidly,
            without worrying about how someone will react, if they will understand
            or judge you, or how your words might impact your personal
            relationship. Minimizing these concerns makes you less likely to hold
            back, and more inclined to explicitly speak your mind, without
            restraint or self-consciousness.
          </p>
          <p>
            If you are the type of person who needs to process things out loud, it
            can also be especially useful to have an impartial audience help you
            sift through thoughts and emotions that are still developing. Talking
            it out in a {session} lets you organize your thoughts and feelings
            privately, without being rushed or pressured to communicate them
            properly before you are ready.
          </p>
          {mobBook}
        </div>
      </div>
    </div>
  )

  const altBen = (
    <div className={[classes.faqWrap, classes.insideTw].join(" ")}>
      <div className={[classes.flexRow, classes.body].join(" ")}>
        <div className={[classes.ilItem, classes.summ, classes.sticky].join(" ")}>
          {/* <h4>An Unconventional Approach</h4> */}
          <h3>An Alternative <br/>to Therapy</h3>
          <p className={classes.cat}>Essentials</p>
          <ul>
            <li>Listening doesn't require a license</li>
            <li>Everyone deserves to have an outlet</li>
            <li>Different methods work for different people</li>
            <li>We just want to help!</li>
          </ul>
          {fullBook}
        </div>

        <div className={[classes.ilItem, classes.info].join(" ")}>
          <p>
            <span className={classes.tag}>
              It doesn't have to be therapy to be therapeutic.
            </span>
            <br />
            We believe in therapy as wholeheartedly as we believe in Vent.
            However, the standard therapy model does not appeal to everyone, nor
            is it accessible to everyone. If you fall into one of those
            categories, shouldn't you have other options?
          </p>
          <p>
            Not having the time, funds, or disposition to attend regular
            appointments with a licensed therapist doesn't make you less worthy of
            the positive effects that come from such a valuable service. If you
            feel anxious and uncomfortable in that kind of formal setting, or you
            feel it adds more pressure to the load you're already carrying, it
            might be harder for you to make progress with your visits. It's also
            possible that you are only interested in a one-off {session} to clear
            your head, and you prefer our more casual approach.
          </p>

          <p>
            Our goal is to provide support when and where it's needed, to the best
            of our ability. We truly believe that talking helps ease the stress
            and pressures that come with life, and we sincerely want everyone to have
            someone who listens — whether they find that with us or somewhere
            else. Figure out what works for you, and let it work for you!
          </p>
          <p className={classes.disclaimer}><Disclaimer /></p>
          {mobBook}
        </div>
      </div>
    </div>
  )

  const us = (
    <div className={[classes.faqWrap, classes.insideTw].join(" ")}>
      <h3>About Vent</h3>
      <div className={[classes.flexRow, classes.body].join(" ")}>
        <div className={[classes.ilItem, classes.summ].join(" ")}>
          <p>
            My name is Kelli, and I started Vent simply because I saw a place
            for it.
          </p>
          <p>
            I realized at a young age that I would be living my life with the
            constant and magical companionship of anxiety. As I got older, I put a lot of time
            and effort into exploring the peaks and valleys of my personal version
            of anxiety — discovering what helped and what hurt, and trying to fit
            it all into manageable pieces.
          </p>
          <p>
            Throughout that process, I developed a strong passion for mental
            health, and how it impacts our daily lives. However, between courses,
            classes, and everyday life, the most important thing I've learned is
            the value and necessity of connecting with people.
          </p>
        </div>

        <div className={[classes.ilItem, classes.info].join(" ")}>
          <p>
            <span className={classes.cat}>
              I believe that everyone needs an outlet,
            </span>{" "}
            even when things are going well. Words carry weight, and saying
            something out loud can be so therapeutic that it instantly releases
            some of that weight from your own shoulders. People hold things in for
            all kinds of reasons — not being ready to discuss or acknowledge
            something, guilt over feeling a certain way, not wanting to burden the
            people they care about, classic self-preservation...but not having
            someone to listen should never be one of those reasons.
          </p>
          <p>
            <span className={classes.cat}>
              I just wanted to give people a simple, comfortable way to blow off
              some steam, feel heard, or get the relief they hope to find.
            </span>{" "}
            Whether it's just a random call when needed or a regular standing
            appointment, my goal is to present a casual option to help people feel
            the support, encouragement, and confidence that comes from having
            someone in their corner.
          </p>
        </div>
        <div className={[classes.fullSec, classes.final].join(" ")}>
          <p className={classes.cat}>In case you need to hear it.</p>
          <p>
            Feeling like you're alone can make anything you're going through, big
            or small, seem out of your control, even when it isn't. Don't
            disregard your mental or emotional exhaustion. Your feelings matter.
            So does your need to express them. Your emotions don't make you a
            burden, but having an outlet will help you offload the burden you're
            feeling.
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <div className={[classes.About, classes.shapeV1].join(" ")}>
      <Helmet>
        <title>
          Find Support When You're Stressed, Anxious, or Just Need Someone to Listen
        </title>
        <meta
          name="description"
          content="Vent provides listening and emotional support when you feel alone, overwhelmed, or you just need someone to talk to. Book a session today, and start feeling better."
        />
      </Helmet>

      <div className={[classes.fullSec, classes.intro].join(" ")}>
        <div className={[classes.medDef, classes.sectionWrap].join(" ")}>
          <div className={[classes.fullSec, classes.tborder].join(" ")}/>
          <h1 className={classes.h1}>You <span className={classes.one}>Vent.</span> We <span className={classes.two}>Listen.</span></h1>
          <h2 className={classes.desc}>
            No one should have to feel alone, especially when they are
            struggling. We offer professional listening and emotional 
            support, for good days and bad. Whether you just need someone to listen occasionally, or you 
            are interested in regular {sessions}, our service is here whenever you need it.
          </h2>
          <div className={[classes.btnRow].join(" ")}><BookNow btnText="Book a Session" addClass="rev" /></div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.introWave].join(" ")}>
        <Wave1 addClass="rev" hide3={true} color2="color1" hide1={true} />
      </div>
      <div className={[classes.fullSec, classes.pageNav].join(" ")}>
        <div className={[classes.optsFlex, "stickyPageMenu"].join(' ')} data-uk-sticky="media: 990">
          {/* <p>Quick Links</p> */}
          <div className={classes.pageNavlink}>
            <button onClick={() => scrollSmoothTo("ovw")} title="The Vent Method" className={classes.textLink}>
              <span className={classes.fullLabel}>Why Should You Vent?</span>
            </button>
          </div>
          <div className={classes.pageNavlink}>
            <button onClick={() => scrollSmoothTo("how")} title="How Does Venting Work" className={classes.textLink}>
              How Does it Work?
            </button>
          </div>
          <div className={classes.pageNavlink}>
            <button onClick={() => scrollSmoothTo("obj")} title="Why Should You Trust Us" className={classes.textLink}>
              Why Trust Us?
            </button>
          </div>
          <div className={classes.pageNavlink}>
            <button onClick={() => scrollSmoothTo("thp")} title="An Alternative to Therapy" className={classes.textLink}>
              Alternative to Therapy
            </button>
          </div>
          <div className={classes.pageNavlink}>
            <button onClick={() => scrollSmoothTo("us")} title="Learn About Vent" className={classes.textLink}>
              About Us
            </button>
          </div>
          <div className={[classes.pageNavlink, classes.book].join(' ')}>
            <BookNow btnStyle="textLink" btnText="Book Now!" />
          </div>
        </div>
      </div>
      
      <div className={[classes.fullSec, classes.outroWave].join(" ")}>
        <Wave1 addClass="rev" hide3={true} color2="lavLt2" hide1={true} />
      </div>

      <div className={[classes.fullSec, classes.fwFaqs].join(" ")}>
        <div id="method" className={[classes.faq, classes.std, classes.method].join(" ")}>
          {method}
        </div>
        <img src={swoopFlip} alt="swoop shape" className={classes.swoopBanner} />
        
        <div id="how" className={[classes.faq, classes.std, classes.how, classes.bg2].join(" ")}>
          {how}
        </div>
        <img src={swoopFlat} alt="swoop shape" className={classes.swoopBanner} />
        
        <div id="obj" className={[classes.faq, classes.std, classes.obj].join(" ")}>
          {objBen}
        </div>
        <img src={swoopFlip} alt="swoop shape" className={classes.swoopBanner} />

        <div id="thp" className={[classes.faq, classes.std, classes.thp, classes.bg2].join(" ")}>
          {altBen}
        </div>
        <img src={swoopFlat} alt="swoop shape" className={classes.swoopBanner} />

        <div id="us" className={[classes.faq, classes.alt, classes.us].join(" ")}>
          {us}
        </div>
      </div>

      <div className={[classes.fullSec, classes.faqAccords].join(" ")} data-uk-accordion>
        <Uccordion title={<h3>How {venting} Works</h3>} open="true" addClass="groupHow">
          <div className={[classes.faq, classes.howMob].join(" ")}>
            {how}
          </div>
        </Uccordion>
        <Uccordion title={<h3>How {venting} Works</h3>} addClass="groupHowSm">
          <div className={[classes.faq, classes.howMob].join(" ")}>
            {how}
          </div>
        </Uccordion>
        <Uccordion title={<h3>Why Should You Vent?</h3>} addClass="groupWhy">
          <div className={[classes.faq, classes.methodMob].join(" ")}>
            {method}
          </div>
        </Uccordion>
        <Uccordion title={<h3>Why Should You Trust Us?</h3>} addClass="groupTrust">
          <div className={[classes.faq, classes.objMob].join(" ")}>
            {objBen}
          </div>
        </Uccordion>
        <Uccordion title={<h3>An Alternative to Therapy</h3>} addClass="groupAlt">
          <div className={[classes.faq, classes.thpMob].join(" ")}>
            {altBen}
          </div>
        </Uccordion>
        <Uccordion title={<h3>About Vent</h3>} addClass="groupUs">
          <div className={[classes.faq, classes.usMob].join(" ")}>
            {us}
          </div>
        </Uccordion>
      </div>
    </div>
  )
}
