import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Home.module.scss"
import { BookNow } from "../../components/Book/Book"
import ventLogo from "../../assets/images/vent.png"
import swoop from "../../assets/images/swoopBg.png"

export default function Home() {
  const venting = <span className={classes.em}>Venting</span>
  const sessions = <span className={classes.em}>Venting Sessions</span>
  const ucv = <span className={classes.ucv}>uncon<span className={classes.sig}>vent</span>ional</span>

  return (
    <div className={[classes.fullSec, classes.Home].join(" ")}>
      <Helmet>
        <title>Listening, Emotional Support, & Companionship</title>
        <meta name="description" content="Vent provides the emotional outlet you need to talk through anything that's bothering you, so you can find relief, and start feeling better." />
      </Helmet>

      <div className={[classes.fullSec, classes.introAlt].join(" ")}>
        <div className={[classes.insideTw, classes.wrap1].join(" ")}>
          <div className={[classes.flexRow, classes.ac, classes.jbw].join(" ")}>
            <div className={[classes.ilitem, classes.i1].join(" ")}>
              <img src={ventLogo} alt="Vent logo - professional listening and alternative therapy" className={classes.rd} />
            </div>
            
            <div className={[classes.ilitem, classes.i2].join(" ")}>
              <h1 className={classes.h1}>Emotional Support<br/> When You Need it Most</h1>
              <h2>
                In today's world, finding someone who truly listens can be a challenge. Vent provides a <b>private, 
                casual,</b> and <b>supportive</b> space where you feel comfortable discussing whatever is on your mind. 
                Whether you're dealing with stress, loneliness, or just need to get something off your chest, 
                our <b>{sessions}</b> are the perfect opportunity for you to unload anything that has been weighing you down.
              </h2>
              <h3>No guilt. No judgement. Just listening.</h3>
              <div className={[classes.fullSec, classes.btnRow].join(" ")}>
                <BookNow />
                <Link to="/about" className={classes.kpLink}>Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={[classes.fullSec, classes.swoopBannerWrap].join(" ")}>
        <img src={swoop} alt="swoop shape" className={classes.swoopBanner} />

        <img src={ventLogo} alt="Vent logo - emotional support and alternative therapy" className={classes.logo} />
        <div className={[classes.topQuote].join(" ")}>
          <p>
            <span className={classes.line1}>If You're Holding It In,</span><br/>
            <span className={classes.line2}>It's Holding You Down</span>
          </p>
        </div>
      </div>

      <div className={[classes.fullSec, classes.summary].join(" ")}>
        <div className={[classes.sectionWrap, classes.insidePw].join(' ')}>
          <div className={[classes.item, classes.iwhy, classes.tbox].join(" ")}>
            <h3>{sessions}</h3>
            <h4>With Your Personal Confidant</h4>
            <ul>
              <li>Our mission is to make you feel heard, and lighten your load. 
                We provide the emotional outlet you need to talk through anything that's occupying your mind, so you can decompress, recharge,
                and feel better.
              </li>
              <li>
                Confidential, one-on-one sessions that give you the opportunity to express and
                process your thoughts and feelings privately, without being rushed or 
                pressured to communicate them before you are ready. </li>
              <li>
                Experienced, passionate, and completely objective listeners who give you the freedom to speak 
                openly, without the fear of being judged, influenced, or damaging a personal relationship.
              </li>
              <li>{venting} is not just for bad days or stress relief. We want to hear your goals, and share your excitement as you face new challenges and experiences.</li>
            </ul>
            <div className={[classes.fullSec, classes.clearfixBtn].join(' ')}>
              <Link to="/about" className={classes.kpLink}>Learn More</Link>
              <BookNow />
            </div>
          </div>

          <div className={[classes.item, classes.ihow, classes.tbox].join(" ")}>
            <h3>We're Here to Hear</h3>
            <h4>Your Terms. Your Pace.</h4>
            <p>{sessions} are meant to empower you, so it's our job to make sure you feel comfortable, and get the most out each encounter.</p>
            <ul>
              <li>
                <p className={classes.hd}>Audio or Video</p>
                <p>
                  Some people find it easier to speak freely when they have anonymity. Some people prefer to feel more of a connection 
                  with the person in whom they are confiding. It's always your choice.
                </p>
              </li>
              <li>
                <p className={classes.hd}>Conversation or Closed Vent</p>
                <p>You set the boundaries for each session.<br/>
                  Interactive {sessions} tend to lead to helpful and productive conversations, but we also know that
                  sometimes you just need to get it out with minimal input or interruption. 
                </p>
                <p>
                  When you opt for Closed Vent, we'll say hello, and let you take off running.
                  We just listen, while you enjoy a true decompression session.
                </p>
              </li>
              <li>
                <p>
                  <span className={classes.semi}>{sessions} are $45, and last for 1 hour.</span> <br/>
                  <span className={classes.req}>After hours appointments by 
                    request. <Link to="/contact" className="textLink">Contact us</Link> for more information.</span>
                </p>
              </li>
            </ul>

            <div className={[classes.fullSec, classes.clearfixBtn].join(' ')}>
              <Link to="/about" className={classes.kpLink}>Learn More</Link>
              <BookNow />
            </div>
          </div>

          <div className={[classes.item, classes.inew_XX, classes.ialt, classes.tbox].join(" ")}>
            <h3>It Doesn't Have to be Therapy<br/> <span>To Be Therapeutic</span></h3>
            <p>
              One of our main goals is to present a compassionate, affordable, and accessible alternative to 
              traditional therapy. Not everyone can afford therapy, and many people feel
              uncomfortable with the formal nature of the standard therapy model.
              That's why we've created a casual, supportive environment 
              where you can talk to someone who genuinely cares, and 
              sincerely believes that everyone deserves to have someone who listens.
            </p>
            <h4>Why not try an {ucv} approach?</h4>
          </div>

          <div className={[classes.quote].join(" ")}>
            <p>
              <span className={classes.line1}>If You're Holding It In,</span><br/>
              <span className={classes.line2}>It's Holding You Down</span>
            </p>
          </div>
        </div>
      </div>
      
      <div style={{display:'none'}} className={[classes.fullSec, classes.hs3].join(" ")}>
        <div className={classes.medDef}>
          <div className={[classes.flexRow, classes.even2, classes.jbw].join(" ")}>
            <div className={[classes.item, classes.i1].join(" ")}>
              <p>
                <b>Talking helps. It's that simple.</b><br/>
                Voicing your thoughts and feelings to another person has such a powerful effect on your mood and mental health. 
                It is one of the most effective ways to sort through emotions, work out solutions to problems, and find relief.
              </p>
            </div>

            <div className={[classes.item, classes.i].join(" ")}>
              <p>
                Bottling up your feelings builds up stress and tension. It creates a sense of isolation, and makes everything you're going 
                through seem bigger and more difficult. Over time, it can also lead to physical pain, and have a negative impact on your health.
              </p>
            </div>
            

            {/* People hold things in for all kinds of reasons, but not having someone to listen should never be one of them. Book a {session} today! */}
            {/* <div className={[classes.tbox, classes.discbox].join(" ")}>
              <p className={classes.disc}>Vent is not a substitution for professional medical care. 
                We offer support, companionship, and encouragement. We do <b>not</b> offer medical diagnoses or treatment.
                If you are in distress, or experiencing thoughts of suicide, please contact a licensed medical professional.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
