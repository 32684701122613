import classes from './Book.module.scss'

// 'https://calendly.com/vent-booking // with multiopts - or use event url as prop
const initBookLink = () => {
  if (typeof window !== 'undefined' && window.Calendly) {
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/vent-booking/hour-session?hide_gdpr_banner=1&primary_color=370054',
    })
    return false
  }
}
const handleClick = (event) => {
  if (initBookLink) {
    initBookLink(event)
  }
}
export function BookNow(props) {
  return (
    <button
      onClick={handleClick}
      className={[classes[props.btnStyle ?? 'kpLink'], classes[props.addClass ?? "bookNow"]].join(' ')}
      title="Book a Venting Session"
      type="button"
    >
      {props.btnText ?? "Book Now"}
    </button>
  )
}