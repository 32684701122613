import React, { Component } from 'react'
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom'
import './assets/uikit.css'
import './App.scss'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import BackToTop from './components/Smooth/BackToTop'
import Home from './Pages/Home/Home'
import Sessions from './Pages/Sessions/Sessions'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

const HeadStyle = () => {
  if (window.location.pathname === '/about') {
    return (<Header version="nomin" />)
  }
  return (<Header version="std" />)
}
const SneakyHeader = withRouter(HeadStyle)


class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/book-now" element={<Sessions />} />
        <Route path="/venting-sessions" element={<Sessions />} />
      </Routes>
    )

    return (
      <div id="ventTop" className="kpWrap fullSec">
        {/* <div className="fullSec attnBanner">
          <div className="sectionWrap medDef">
            <h3>Heads up!</h3>
            <p>
              New sessions will be paused from <b>September 16-26.</b><br/> <b>All standing appointments during 
              those dates will still be heard at their regularly scheduled times.</b> We appreciate your understanding, and 
              look forward to welcoming new Venters again the following week!
            </p>
          </div>
        </div> */}
        <SneakyHeader />
        <main className="fullSec mainWrap">
          {routes}
        </main>
        <Footer />
        <BackToTop />
      </div>
    )
  }
}
export default App