import React, {Component} from 'react'
import { Link } from "react-router-dom"
import classes from "./Header.module.scss"

class NavItems extends Component {
  render() {
    return(
      <ul className={[classes[this.props.navClass]].join(' ')}>
        {this.props.hideHome !== true ? <li className={[classes.navItem, classes.ihome].join(' ')}><Link to="/">Home</Link></li>: null}
        <li className={[classes.navItem, classes.iabout].join(' ')}><Link to="/about">About</Link></li>
        <li className={[classes.navItem, classes.icontact].join(' ')}><Link to="/contact">Contact</Link></li>
      </ul>
    );
  }
}
export default NavItems;