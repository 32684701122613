import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Sessions.module.scss"
import { BookNow } from "../../components/Book/Book"
import swoopFlat from "../../assets/images/swoopFlat.png"

export default function Sessions() {
  const session = <span className={classes.em}>Venting Session</span>
  const sessions = <span className={classes.em}>Venting Sessions</span>
  const venting = <span className={classes.em}>Venting</span>
  const ucv = <span className={classes.ucv}>uncon<span className={classes.sig}>vent</span>ional</span>

  const textSlider = (
    <h2 className={[classes.h1].join(' ')}>
      Book a Venting Session to<br/>
      <div
        className={[classes.fullSec, classes.ukTextSlide].join(' ')}
        tabIndex={-1}
        data-uk-slideshow="animation:fade; autoplay:true; draggable:false; ratio:false; pause-on-hover:false; autoplay-interval:4000;"
      >
        <ul className={[classes.ukSlides, "uk-slideshow-items"].join(' ')}>
          <li className={classes.one}>Get it Out</li>
          <li className={classes.two}>Stop Feeling Drained</li>
          <li className={classes.three}>Clear Your Mind</li>
          <li className={classes.four}>Feel Less Alone</li>
          <li className={classes.five}>Start Feeling Better</li>
        </ul>
      </div>
    </h2>
  )

  return (
    <div className={[classes.fullSec, classes.Sessions].join(" ")}>
      <Helmet>
        <title>Private Venting Sessions When You're Stressed or Lonely</title>
        <meta name="description" content="Feeling stressed, overwhelmed, or just need someone to listen? Book a Venting Session today to talk through whatever is on your mind." />
      </Helmet>

      <div className={[classes.fullSec, classes.intro, classes.bg2].join(" ")}>
        <div className={[classes.med4Def, classes.h1Wrap].join(" ")}>
          <h1>Feeling <span>stressed</span>, <span>overwhelmed</span>, or<br/> just <span>need someone to listen?</span></h1>
          <div className={[classes.fullSec, classes.vborder, classes.one].join(" ")}></div>
          {textSlider}
          <div className={[classes.fullSec, classes.vborder, classes.two].join(" ")}></div>
          <div className={classes.clearfixBtnNoo}><BookNow btnText="Book Now!" /></div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.bg2, classes.ovw].join(" ")}>
        <div className={[classes.medDef, classes.flexDef].join(" ")}>
          <div className={[classes.flexRow].join(" ")}>
            <div className={classes.one}>
              <ul>
                <li><b>1 Hour Session <span>&middot;</span> $45</b></li>
                <li>Reserve a date & time</li>
                <li>Customize session options</li>
                <li>Get link in confirmation email</li>
              </ul>
            </div>
            <div className={classes.two}>
              <p>
                We're here to make you feel heard, and lighten your load. Your private {session} gives you the outlet you need to talk through whatever is occupying your thoughts or weighing you down.
                Venting helps you clear your head, reduce stress, and feel better. <Link to="/about" className={classes.textLink}>Click here</Link> for 
                a deeper look into what we do and how we can help, and book a session today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <img src={swoopFlat} alt="swoop shape" className={classes.swoopBanner} />

      <div className={[classes.fullSec, classes.summary].join(" ")}>
        <div className={[classes.sectionWrap, classes.insidePw].join(' ')}>
          <div className={[classes.item, classes.iwhy, classes.tbox].join(" ")}>
            <h3>No guilt. No judgement.</h3>
            <h4>Just listening.</h4>
            <ul>
              <li>
                Confidential, one-on-one sessions that give you the opportunity to express and
                process your thoughts and feelings privately, without being rushed or 
                pressured to communicate them before you are ready. </li>
              <li>
                Experienced, passionate, and completely objective listeners who give you the freedom to speak 
                openly, without the fear of being judged, influenced, or damaging a personal relationship.
              </li>
              <li>{venting} is not just for bad days or stress relief. We want to hear your goals, and share your excitement as you face new challenges and experiences.</li>
            </ul>
            <div className={[classes.fullSec, classes.clearfixBtn].join(' ')}>
              <Link to="/about" className={classes.kpLink}>Learn More</Link>
              <BookNow />
            </div>
          </div>

          <div className={[classes.item, classes.ihow, classes.tbox].join(" ")}>
            <h3>We're Here to Hear</h3>
            <h4>Your Terms. Your Pace.</h4>
            <p>{sessions} are meant to empower you, so it's our job to make sure you feel comfortable, and get the most out each encounter.</p>
            <ul>
              <li>
                <p className={classes.hd}>Audio or Video</p>
                <p>
                  Some people find it easier to speak freely when they have anonymity. Some people prefer to feel more of a connection 
                  with the person in whom they are confiding. It's always your choice.
                </p>
              </li>
              <li>
                <p className={classes.hd}>Conversation or Closed Vent</p>
                <p>You set the boundaries for each session.<br/>
                  Interactive {sessions} tend to lead to helpful and productive conversations, but we also know that
                  sometimes you just need to get it out with minimal input or interruption. 
                </p>
                <p>
                  When you opt for Closed Vent, we'll say hello, and let you take off running.
                  We just listen, while you enjoy a true decompression session.
                </p>
              </li>
              <li>
                <p>
                  <span className={classes.semi}>{sessions} are $45, and last for 1 hour.</span> <br/>
                  <span className={classes.req}>After hours appointments by 
                    request. <Link to="/contact" className="textLink">Contact us</Link> for more information. *Sessions 
                    canceled within 2 hours of starting will not be refunded.</span> 
                </p>
              </li>
            </ul>

            <div className={[classes.fullSec, classes.clearfixBtn].join(' ')}>
              <Link to="/about" className={classes.kpLink}>Learn More</Link>
              <BookNow />
            </div>
          </div>

          <div className={[classes.item, classes.ialt, classes.tbox].join(" ")}>
            <h3>It Doesn't Have to be Therapy<br/> <span>To Be Therapeutic</span></h3>
            <p>
              One of our main goals is to present a compassionate, affordable, and accessible alternative to 
              traditional therapy. Not everyone can afford therapy, and many people feel
              uncomfortable with the formal nature of the standard therapy model.
              That's why we've created a casual, supportive environment 
              where you can talk to someone who genuinely cares, and 
              sincerely believes that everyone deserves to have someone who listens.
            </p>
            <h4>Why not try an {ucv} approach?</h4>
          </div>
        </div>
      </div>
    </div>
  )
}
